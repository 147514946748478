<template>
  <div>
    <el-card>
      <div class="flexRow">
        <div class="stateBox" :class="{success:form.State==1}">{{form.State==0?'未开始':(form.State==1?'进行中':'已结束')}}</div>
        <div class="title">{{decodeURIComponent($route.query.Name)}}</div>
      </div>
      <div class="flexRow" style="margin-top:20px;">
        <div class="label">
          积分数量：
        </div>
        <div class="label" style="font-weight:400">
          1个码可兑换{{form.ExchangePoint}}积分
        </div>
      </div>
      <div class="flexRow" style="margin-top:20px">
        <div class="label">
          兑换时间：
        </div>
        <div class="label" style="font-weight:400;width:auto">
          {{form.IsPermanentActivity?'永久有效':`${form.StartTime}至${form.EndTime}`}}
        </div>
      </div>
    </el-card>
    <el-card style="margin-top:10px">
      <div class="flexRow">
        <div class="flexRow">
          <div class="label">
            关键字：
          </div>
          <el-input v-model="keywords" style="width:220px" placeholder="积分兑换码"></el-input>
        </div>
        <el-button type="primary" style="margin-left:10px" @click="currentPage=1,getinfo()">查询</el-button>
        <el-button type="primary" @click="pushShow=true,getnum()">添加总量</el-button>
      </div>
      <div style="margin-top:10px">
        <el-table :data="tablelist" v-loading="tableloading" ref="table" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="Code" label="兑换码"></el-table-column>
          <el-table-column prop="AddTime" label="生成时间"></el-table-column>
          <el-table-column prop="ExchangeState" label="兑换状态"></el-table-column>
          <el-table-column prop="ExchangeTime" label="兑换时间">
            <template slot-scope="scope">
              {{scope.row.ExchangeTime||'--'}}
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top:10px" class="flexRow" v-if="total">
          <div class="flexRow">
            <el-checkbox :value="allcheck" @change="allchoose" style="margin-right:10px">当前页全选</el-checkbox>
            <el-button size="mini" @click="exportlist(1)">导出当前页选中的数据</el-button>
            <el-button size="mini" @click="exportlist(2)">导出全部数据</el-button>
          </div>
          <el-pagination
            style="margin-left:auto"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </el-card>

    <el-dialog :visible.sync="pushShow" title="添加发放总量" custom-class="dialog-body-paddingTop-10" @closed="pushShow=false,addNum=''" width="500px"
      v-loading="pushloading">
      <div class="flexRow" style="margin-bottom:20px">
        <div class="label" style="margin-left:4px">发放总量：</div>
        <div>
          {{form.SendCount}}
        </div>
      </div>
      <div class="flexRow" style="margin-bottom:20px">
        <div class="label" style="margin-left:4px">剩余数量：</div>
        <div>
          {{form.SendCount - form.ExchangedCount}}
        </div>
      </div>
      <div class="flexRow" style="margin-bottom:20px;align-items:start">
        <div class="label star" style="line-height:36px">添加数量：</div>
        <div>
          <el-input style="width:300px" placeholder="单次添加请勿超过10000" v-model="addNum"
            onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
            onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
          </el-input>
          <div class="grayfont" style="margin-top:8px">兑换码总量，只可增加，不可减少</div>
        </div>
      </div>
      <div style="text-align:right;margn-top:30px">
        <el-button @click="pushShow=false,addNum=''">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  activityPointCodeexchangeDetail,
  activityPointCodeinfo,
  activityPointCodeaddSendCount
} from "@/api/sv3.0.0"
export default {
  computed: {
    allcheck(){
      return this.tablelist.every(v=>{
        return this.chooselist.some(x=>x.Id==v.Id)
      })
    }
  },
  data () {
    return {
      form:{
        State:0,
        IsPermanentActivity:true,
        StartTime:'',
        EndTime:'',
        SendCount:0,
        ExchangedCount:0,
        ExchangePoint:0,
      },
      keywords:'',
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:0,
      chooselist:[],
      pushShow:false,
      addNum:'',
      pushloading:false,

    }
  },
  mounted () {
    this.getinfo()
  },
  methods: {
    async save(){
      if(this.addNum<1||this.addNum>10000){
        this.$message.error('单次添加，数量请控制在1~10000之间，整数')
        return
      }
      // if((Number(this.addNum)+Number(this.form.SendCount))>10000000){
      //   // console.log(this.addNum+this.form.SendCount)
      //   this.$message.error('添加后，发放总量请控制在10000000及以内')
      //   return
      // }
      if(this.form.State==2){
        this.$message.error('活动已结束，添加失败')
        return
      }
      try{
        this.pushloading = true
        let res = await activityPointCodeaddSendCount({
          Id:this.$route.query.Id,
          AddSendCount:this.addNum
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.pushShow = false
          this.getinfo()
        }
      }finally{
        this.pushloading = false
      }
    },
    exportlist(type){
      if(type==1&&(!this.chooselist||!this.chooselist.length)){
        this.$message.error('请选择要导出的数据')
        return
      }
      let url = location.origin + '/pc/activityPointCode/exchangeDetailExport'
      url += '?Id=' + this.$route.query.Id
      url += '&Code=' + this.keywords
      url += '&IdList=' + (type==1?this.chooselist.map(v=>v.Id):[])
      // console.log(url)
      window.open(url)
    },
    allchoose(){
      this.$nextTick(()=>{
        this.$refs.table.toggleAllSelection()
      })
    },
    handleSelectionChange(list){
      this.chooselist = list
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.getinfo()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.getinfo()
    },
    async getinfo(){
      try{
        this.tableloading = true
        let res = await activityPointCodeexchangeDetail({
          Id:this.$route.query.Id,
          Code:this.keywords,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize,
        })
        if(res.IsSuccess){
          for(let key in this.form){
            this.form[key] = res.Result[key]||''
          }
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    async getnum(){
      try{
        this.loading = true
        let res = await activityPointCodeinfo({
          Id:this.$route.query.Id
        })
        if(res.IsSuccess){
          this.form.SendCount = res.Result.SendCount
          this.form.ExchangedCount = res.Result.ExchangedCount
        }
      }finally{
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.flexRow{
  display: flex;
  align-items: center;
}
.stateBox{
  // margin-left:10px;
  background: #F8F8F9;
  padding: 3px 15px;
  border-radius: 0px 0px 0px 0px;
  border: 1px solid #EBEEF5;
  font-size: 12px;
  color: #606266;
  line-height: 18px;
  &.success{
    color: #67C23AFF;
    border: 1px solid #C2E7B0;
    background: #F0F9EB;
  }
}
.title{
  font-size: 22px;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
  margin-left: 10px;
}
.label{
  font-size: 14px;
  color: #606266FF;
  white-space: nowrap;
  flex-shrink: 0;
  font-weight: bold;
  line-height: 1.5;
  // width: 90px;
  text-align: right;
  &.star{
    &::before{
      content: '*';
      color: #f56c6c;
      margin-right:4px
    }
  }
}
.grayfont{
  font-size: 14px;
  color: #999999;
  font-weight: 400;
  line-height: 1.5;
}
</style>